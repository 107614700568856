import React from 'react'
import styled from 'styled-components'

// Pagination Styling
const PaginationWrapper = styled.div`
  display block;
  padding-top: 15px;

  > div {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin: 0px 12px 0 0;
  }
`
const PageNext = styled.div`
  padding: 10px 22px;
  border-radius: 12px;
  font-size: 0.9em;
  border: 1px solid rgba(0,0,0,.1);

  &:hover { background: rgba(0,0,0,0.1); }

  ${props => props.isDisabled ? 'opacity: 0.4; cursor: blocked;' : ''}
`
const PagePrevious = styled.div`
  padding: 10px 22px;
  border-radius: 12px;
  font-size: 0.9em;
  border: 1px solid rgba(0,0,0,.1);

  &:hover { background: rgba(0,0,0,0.1); }

  ${props => props.isDisabled ? 'opacity: 0.4; cursor: blocked;' : ''}
`
const PageSelector = styled.div`
  float: right;
  padding: 8px 22px;
  border-radius: 12px;
  border: 1px solid rgba(0,0,0,.1);
  margin-right: 0;

  select {
    border: 0px solid #000;
  }
`

export const useDetermineAmountofPages = (count = 0, amountOfItems = 0) => {
  const calc = () => {
    if (count && amountOfItems && count > amountOfItems) {
      if (count > amountOfItems) {
        return Math.ceil(count/amountOfItems)
      }
    }

    return 1
  }
  const [state, setState] = React.useState(calc())

  React.useEffect(() => {
    setState(calc())
  }, [count])

  return state
} 

// Pagination Helpers
const buildPageOptions = (amount, translations) => {
  if (!amount || amount === 0) {
    return []
  }

  let pageList = []
  for (let i = 1; i <= amount; i++) {
    pageList.push({
      label: `${translations?.optionPrefix || ''}${i}`,
      value: i,
      key: `pagination-${Math.floor(Math.random() * 1000)}-${i}`
    })
  }
  return pageList
}

// Pagination Module
const PaginationModule = ({
  numberOfPages, // Number of pages
  translations = {}, // Translations object
  currentPage = 1, // Current page by default
  handleNext, // Handle the click of next page
  handlePrevious, // Handle the click of previous page
  handleGoToPage, // Handle go to page
  doNotRequestNewPageOnUpdate // Boolean, whenever currentPage is changed, do no fire a page request
}) => {
  // State of which page we're on
  const [pageState, setPageState] = React.useState(currentPage)
  
  // Event handlers
  const onHandleNextPage = () => {
    if (handleNext && typeof handleNext === 'function') {
      handleNext()
      setPageState((pageState + 1))
    }
  }

  const onHandlePreviousPage = () => {
    if (handlePrevious && typeof handlePrevious === 'function') {
      handlePrevious()
      setPageState((pageState - 1))
    }
  }

  const onHandleSwitchPage = (newPage) => {
    if (handleGoToPage && typeof handleGoToPage === 'function') {
      handleGoToPage(newPage)
      setPageState(newPage)
    }
  }

  // react to external updates
  React.useEffect(() => {
    if (currentPage !== pageState) {
      setPageState(currentPage)

      if (
        !doNotRequestNewPageOnUpdate
      ) {
        onHandleSwitchPage(currentPage)
      }
    }
  }, [currentPage])

  // Build list of pages to jump too
  const PageSelectors = buildPageOptions(numberOfPages, translations)

  // No Pages, do not render anything
  if (!PageSelectors || PageSelectors.length === 0) {
    return <div></div>
  }

  // Disable states for buttons
  const isPreviousDisabled = pageState === 1
  const isNextDisabled = pageState === numberOfPages || numberOfPages === 1

  // We have stuff to render
  return <PaginationWrapper>
    <PagePrevious
      isDisabled={isPreviousDisabled}
      onClick={() => {
        if (!isPreviousDisabled) {
          onHandlePreviousPage()
        }
      }}
    >
      { translations.previous }
    </PagePrevious>
    {<PageNext
      onClick={() => {
        if (!isNextDisabled) {
          onHandleNextPage()
        }
      }}
      isDisabled={isNextDisabled}
    >
      { translations.next }
    </PageNext>}
    <PageSelector>
      <select onChange={(e) => {
        const value = parseInt(e.target.value)
        onHandleSwitchPage(value)
      }}>
        {
          PageSelectors.map(item => <option
            selected={item.value === pageState}
            value={item.value}
            key={item.key}
          >
            {item.label}
          </option>)
        }
      </select>
    </PageSelector>
  </PaginationWrapper>
}
export default PaginationModule
