import styled from 'styled-components'

const LoginView = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background: #efefef;
  padding: 80px 0;

  form {
    padding: 12px 0 0;
  }

  p.error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
  }
`
export default LoginView