import React from 'react'

// Helpers
import { postApi, fetchApi } from '../apis/utils/fetch'
import LoadingContainer from '../core/modules/loading-container'

// My context
const AuthContext = React.createContext()

// Globals
const STORAGEKEY = '__sd_gww_auth_provider_token__'
export const getToken = () => window.localStorage.getItem(STORAGEKEY)

// Use context hook
export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used with-in the provider')
  }
  return context
}

// Provider
const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState({})
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  // Logout
  const logout = () => {
    const removeLocalSession = () => {
      setUser({})
      setError(false)
      window.localStorage.removeItem(STORAGEKEY)
    }

    fetchApi('logout').then(removeLocalSession).catch(removeLocalSession)
  }

  const recoverPassword = (details) => {
    postApi('reset-password/', details).catch(() => setError(true))
    setError(false)
    setUser({})
    window.localStorage.removeItem(STORAGEKEY)
  }

  // Login
  const authenticate = async account => {
    if (account) {
      postApi('authentication', account).then((payload) => {
        window.localStorage.setItem(STORAGEKEY, payload.accessToken)
        setUser(payload)
      }).catch((error) => {
        setError(true)
        logout()
      })
    }
  }

  // Restore user from token
  React.useEffect(() => {
    const token = getToken()

    if (token) {
      fetchApi('me').then((user) => {
        setUser(user)
        setLoading(false)
      }).catch(() => {
        logout()
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])

  // Render
  return (
    <AuthContext.Provider value={{ logout, recoverPassword, user, authenticate, authError: error }}>
      <LoadingContainer isLoading={loading}>
        {children}
      </LoadingContainer>
    </AuthContext.Provider>
  )
}

export default AuthProvider
