import React from 'react';

// State and fetch helpers
import { useAppState } from './app-state';
import { postApi, fetchApi, putApi, deleteApi } from './fetch';

// Generic way to determine time
const buildDateTime = () => new Date().getTime() / 1000;

export const constructParamaters = (url, params = {}) => {
  let newUrl = url;

  const iterateAndSetQueryParameters = () => {
    for (const property in params) {
      let notation = newUrl.includes('?') ? '&' : '?';

      if (property && (params[property] !== null || params[property] !== undefined)) {
        newUrl = `${newUrl}${notation}${property}=${params[property]}`;
      }
    }

    return newUrl
  };


  newUrl = iterateAndSetQueryParameters(newUrl, params);

  return `${newUrl}`;
};

export const constructFilterParameters = (filters) => {
  let params = {};

  const buildParams = (params, filter) => {
    const { name, value } = filter;
    params[name] = value;
    return params
  }

  if (Array.isArray(filters)) {
    for (const filter of filters) {
      params = buildParams(params, filter)
    }
  } else {
    const filter = filters; // Determined the filter not to be an aray
    params = buildParams(params, filter)
  }

  return params
}

// Generic reusable state hook
//
// Taps into the application state,
// Fetches and caches the request data
// Provides feedback on request state
//
const useFetchState = (passedKey, passedEndpoint) => {

  // Key should not change if the prop passed changes
  const [setKey] = React.useState(passedKey);
  const [paginated, setPaginated] = React.useState(false);

  // Connect the reusable hook to the global state
  //
  // getStateForEndpoint -> is a function for getting the global state for this endpoint
  // setStateForEndpoint -> is a function for setting global state for the endpoint.
  const { getStateForEndpoint, setStateForEndpoint, CSRFTokenHeader, csrfToken } = useAppState();
  const { data, filterParams, isError, isLoading, error, cache, unAuthenticated, cacheBusted, found } = getStateForEndpoint(setKey);
  const count = data.count;
  const hasPaginatedData = data.count > 1;
  let isProcessing = false;

  // Forced user state update.
  const setState = payload => {
    setStateForEndpoint(setKey, payload);
  };

  // Handle errors in a generic way.
  const handleError = error => {
    setStateForEndpoint(setKey, {
      error,
      isError: true,
      isLoading: false,
      data: {},
      cacheBusted: false,
      unAuthenticated: error === 'unAuthenticated',
    });
  };

  // Handling fetch in a generic way
  //
  // case 1: General use cases doesn't use pagination
  // case 2: Paginated URL can be passed
  const handleFetch = async (url) => {
    fetchApi(url, { [CSRFTokenHeader]: csrfToken })
    .then(payload => {
      setStateForEndpoint(setKey, {
        data: payload,
        isLoading: false,
        isError: false,
        error: {},
        cacheBusted: false,
        cache: buildDateTime(),
        unAuthenticated: false
      })
    })
    .catch(data => {
      handleError(data);
    });
  };

  // Handle fetching the latest data from this endpoint.
  const fetchRequest = async () => {
    return handleFetch(passedEndpoint);
  };

  // Pass a method to map the post endpoint to the data.
  const postRequest = async (requestPayload, saveresponse) => {
    setState({ isLoading: true });

    return postApi(passedEndpoint, requestPayload, { [CSRFTokenHeader]: csrfToken })
      .then(payload => {
        fetchRequest();

        return payload;
      })
      .catch(data => {
        handleError(data);
      });
  };

  // generic way to handle the put, post and delete
  const putDeleteRequest = async (func, id, requestPayload) => {
    setState({ isLoading: true });

    return func(passedEndpoint, id, requestPayload, { [CSRFTokenHeader]: csrfToken })
      .then(payload => {
        fetchRequest();
        return payload;
      })
      .catch(data => {
        handleError(data);
      });
  };

  // API for put and delete
  const putRequest = async (id, payload) => {
    return putDeleteRequest(putApi, id, payload);
  };

  const deleteRequest = async (id, payload) => {
    return putDeleteRequest(deleteApi, id, payload);
  };

  // API for pagination
  //
  // Generic go to
  const goToPage = async (pageNumber, filterValue) => {
    console.log(passedEndpoint);
    
    let params = {
      page: pageNumber,
    }

    if (filterValue) {
      let activeFilter = filterValue.toLowerCase();
      console.log(activeFilter);
      if (activeFilter) {
        params[activeFilter] = true;
      }
    }
    

    const buildUrl = constructParamaters(`${passedEndpoint}`, params);
    setPaginated(true);
    return handleFetch(buildUrl);
  };
  const nextPage = async (filter) => {
    const call = data?.next;
    if (call) {
      setPaginated(true);
      
      return handleFetch(call.split('/api/')[1]);
    }
  };

  const previousPage = async () => {
    const call = data?.previous;
    if (call) {
      setPaginated(true);
      return handleFetch(call.split('/api/')[1]);
    }
  };

  const executeFilter = async filters => {
    const params = constructFilterParameters(filters);
    const buildUrl = constructParamaters(`${passedEndpoint}`, params);

    setState(params);
    return handleFetch(buildUrl);
  };

  // Mounting hook
  // Init the global state for this endpoint.
  React.useEffect(() => {
    if (!found) {
      setStateForEndpoint(
        setKey,
        {
          data: {},
          isLoading: true,
          isError: false,
        },
        true,
      );
    }

    if (passedEndpoint) {
      // If cache is there, check if 15 minutes have passed before rerequesting
      // If cache is not there, do the initial get request.
      if (cache) {
        const timeNow = buildDateTime();
        const hasBeen15Minutes = timeNow >= cache + 900;

        if (hasBeen15Minutes) {
          fetchRequest();
        }
      } else {
        fetchRequest();
      }
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (paginated) {
        setStateForEndpoint(setKey, {
          error: false,
          isError: false,
          isLoading: true,
          data: {},
          cacheBusted: false,
          cache: null,
          unAuthenticated: false,
        });
      }
    };
  }, [paginated]);

  // Check for cacheBusting
  React.useEffect(() => {
    if (cacheBusted && !isProcessing) {
      isProcessing = true;

      handleFetch(passedEndpoint);
    }
  }, [cacheBusted]);

  // Return the state for this endpoint.
  return {
    data, // Full response from the backend
    count,
    hasPaginatedData,
    setState, // Allows the user to overwrite state
    isError, // Is error bollean
    isLoading, // Is loading bollean
    error, // Error message from response
    postRequest, // FUNC -> Post request
    putRequest, // FUNC -> put request
    fetchRequest, // FUNC -> fetch request
    deleteRequest, // FUNC -> delete request
    goToPage, // FUNC -> page GET request
    nextPage, // FUNC -> next page GET request
    previousPage, // FUNC -> previous page GET request,
    filterParams,
    executeFilter, // FUNC -> Executes the dynamic filter
    unAuthenticated, // Boollean, if the user tried to make an unauthenticated request.
  };
};

export default useFetchState;