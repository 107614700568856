// BarChart.jsx

import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (
  {
    label,
    groups
  }
) => {

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
        tools: {
          download: `<img src="https://img.icons8.com/material-outlined/24/000000/download--v1.png" alt="Download" />`,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: groups.map(g => g.name),
    },
    title: {
      text: label,
      align: 'left',
    },
  };

  const chartSeries = [
    {
      name: 'Expiration',
      data: groups.map(g => g.value)
    },
  ];

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        width={800}
      />
    </div>
  );
};

export default BarChart;
