import React from 'react'
import { withRouter, useParams } from 'react-router-dom'
import styled from 'styled-components'

import useOrders from '../apis/orders'
import useOrderItems from '../apis/orderitems'
import LicensesComponent from '../components/licenses'

// Assests
import backbutton from '../core/assets/backbutton.png'

// Generic components
import Header from '../components/header'
import MaxWidth from '../core/modules/max-width-content'
import PageTitle from '../core/modules/page-title'
import { useTranslations } from '../translations/useTranslations'
import OrderDetails from '../components/orders/orderDetails'
import Footer from '../components/footer'
import LoadingContainer from '../core/modules/loading-container'

// Back button
const BackButton = styled.div`
  cursor: pointer;
  position: relative;
  top: 52px;
  left: 0;
  color: #9f9f9f;
  z-index: 999;
  display: inline;

  img {
    margin-right: 8px;
    height: 13px;
  }

  &:hover {
    opacity: 0.7;
  }
`

// Screen component
const ManageOrderScreen = ({ history }) => {
  // Order data
  const { id: orderId } = useParams()

  const orderItems = useOrderItems(orderId)
  const { data, isLoading, isError, putRequest, fetchRequest } = useOrders(orderId)

  // translations
  const { getTranslation } = useTranslations()
  const [orderData, setOrderData] = React.useState({})
  const [error, setError] = React.useState(false)
  const [internalLoading, setIsLoading] = React.useState(true)

  // On mount, fetch the user data
  React.useEffect(() => {
    if (!data || !data.id) {
      setError(true)
    } else {
      if (data && data.id) {
        setOrderData(data)
        orderItems.fetchRequest()
        setIsLoading(false)
        setError(false)
      }
    }
  }, [orderId, data])

  // Handle the user changing the data
  const updateUser = (key, value) => {
    const newState = {
      ...orderData,
      [key]: value
    }

    setOrderData(newState)
  }

  // Handle back
  const handleBack = () => {
    history.push('/orders')
  }

  // Render the component
  return <>
    <Header />
    <MaxWidth>
      {!internalLoading && <><BackButton onClick={handleBack}>
        <img src={backbutton} alt='back to orders' />
        {getTranslation('order.manage.back')}
      </BackButton>
      <PageTitle
        title={getTranslation('order.manage.title', [orderData.ref_id])}
        buttonText={getTranslation('order.manage.button')}
        handleClick={() => {
          putRequest(orderData.id, orderData).then(() => {
            history.push('/orders')
          })
        }}
      /></>}

      <LoadingContainer
        isLoading={(isLoading && internalLoading)}
        isError={isError || error}
      >
        <>
          <OrderDetails
            data={orderData}
            secondary
            handleChange={(key, value) => updateUser(key, value)}
          />

          {orderData && orderData.id && <LicensesComponent
            order={orderData}
            orderItems={orderItems}
            fetchOrder={fetchRequest}
          />}
        </>
      </LoadingContainer>
      <Footer />
    </MaxWidth>
  </>
}

export default withRouter(ManageOrderScreen)
