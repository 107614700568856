import styled from 'styled-components'

export const P = styled.p`
  padding: 0;
  margin: 0;
`

export const H1 = styled.h1`
  font-size: 34px;
  padding-bottom: 6px;
`

export const H3 = styled.h3`
  font-size: 17px;
`

export const H2 = styled.h2`
  font-size: 24px;
  padding-top: 30px;
`

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 800;
  padding-bottom: 6px;
`