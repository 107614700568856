import React from 'react'
import { withRouter } from 'react-router-dom'

// View
import LoginComponent from '../components/login'

const LoginScreen = (props) => {
  return <LoginComponent {...props} />
}

export default withRouter(LoginScreen)