import React from 'react'
import styled from 'styled-components'

// Core
import { PageTitle } from '../ui/typo'
import { Button } from '../ui/fields'

// Module styling
const PageTitleWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 10px;

  .buttonHolder {
    float: right;
    margin-top: -9px;
  }

  h1, h2, h3, h4, h5, h6 {
    display: inline-block;
  }
`

// Component
const PageTitleModule = ({ title, buttonText, handleClick }) => {
  return <PageTitleWrapper>
    <PageTitle> {title} </PageTitle>
    {
      buttonText && handleClick && <div className='buttonHolder'>
        <Button primary onClick={handleClick}>
          {buttonText}
        </Button>
      </div>
    }
  </PageTitleWrapper>
}

export default PageTitleModule