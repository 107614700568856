import React from 'react'

// Core Modules
import Panel from './panel'
import Center from './center'
import Overlay from './overlay'

// Composition Module
const OverlayPanel = ({ children, isOpen, handleClick, contentWidth }) => {
  return <Overlay isOpen={isOpen} handleClick={handleClick}>
    <Center contentWidth={contentWidth}>
      <Panel handleClick={handleClick}>
        {children}
      </Panel>
    </Center>
  </Overlay>
}
export default OverlayPanel
