import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../components/header';
import MaxWidth from '../core/modules/max-width-content';
import { useTranslations } from '../translations/useTranslations';
import PageTitleSlots from '../core/modules/page-title-slots';
import BarChart from '../components/charts/BarChart';
import LineChart from '../components/charts/LineChart';
import PieChart from '../components/charts/PieChart';
import { fetchApi } from '../apis/utils/fetch';
import styled from 'styled-components';
import MultiLineChart from '../components/charts/MultiLineChart';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 columns */
  grid-template-rows: 1fr 1fr 1fr; /* 2 rows */
  gap: 10px; /* Optional: spacing between grid items */
  height: 100vh; /* Optional: full viewport height */
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const ReportScreen = (props) => {
  const { getTranslation } = useTranslations();
  const [orderResults, setOrderResults] = React.useState([]);
  const [totalResults, setTotalResults] = React.useState([])
  const [totalProductResults, setTotalProductResults] = React.useState([])
  const [totalExpirationDateResults, setTotalExpirationDateResults] = React.useState([])
  const [byYearlyMonthlyResults, setByYearlyMonthlyResults] = React.useState([])

  useEffect(() => {
    // Fetch data only on component mount
    fetchApi('v1/orders/reports/')
      .then(_orderResults => {
        setOrderResults(_orderResults);

        const _totalResults = _orderResults.map(order => {
          return {
            name: order.company,
            value: order.active
          }
        })
        
        setTotalResults(_totalResults);

        const _totalProductResults = []
        for (let i = 0; i < _orderResults.length; i++) {
          const orderResult = _orderResults[i];

          Object.entries(orderResult.license_by_product).forEach(e => {
            const [product_name, num_active_licenses] = e;
            const existingProductResult = _totalProductResults.find(p => p.name === product_name);
            if (!existingProductResult) {
              _totalProductResults.push({
                name: product_name,
                value: num_active_licenses
              })
            } else {
              existingProductResult.value = existingProductResult.value + num_active_licenses;
            }
          })
        }

        setTotalProductResults(_totalProductResults);

        const _licenseTotalExpirationDateResults = [];

        for (let i = 0; i < _orderResults.length; i++) {
          const orderResult = _orderResults[i];
        
          Object.entries(orderResult.licenses_by_expiration).forEach(e => {
            const [date, product_counts] = e;
            const expirationDate = new Date(date);
        
            // Group by "Month-Year" format (YYYY-MM)
            const monthYear = `${expirationDate.getFullYear()}-${('0' + (expirationDate.getMonth() + 1)).slice(-2)}`;
        
            const existingExpirationDate = _licenseTotalExpirationDateResults.find(p => p.name === monthYear);
            const orderInfo = {
              num: 0,
              license_ids: [],
              company: orderResult.company,
            }
            
            product_counts.forEach(pc => orderInfo.num += pc.count);
        
            if (existingExpirationDate) {
              existingExpirationDate.value += orderInfo.num; 
            } else {
              _licenseTotalExpirationDateResults.push({
                name: monthYear,
                value: orderInfo.num
              });
            }
          });
        }

        // Get current date and date one year from now
        const now = new Date();
        const nextYear = new Date(now);
        nextYear.setFullYear(now.getFullYear() + 1);

        // Format function to compare against "name" field in data
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2-digit month
            return `${year}-${month}`;
        };

        // Filter data for the range between now and one year from now
        const filteredData = _licenseTotalExpirationDateResults.filter(item => {
            const itemDate = new Date(item.name + "-01"); // Assume day as 01 for comparison
            return itemDate >= now && itemDate <= nextYear;
        });

        // Function to map month number to month name
        const getMonthName = (month) => {
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return monthNames[month - 1];
        };

        // Group data by month name
        const groupedData = filteredData.reduce((acc, item) => {
            const [year, month] = item.name.split('-');
            const monthName = `${getMonthName(parseInt(month))} ${year}`; // Include year in the grouping

            if (!acc[monthName]) {
                acc[monthName] = { name: monthName, value: 0 };
            }
            
            acc[monthName].value += item.value;
            
            return acc;
        }, {});

        // Convert the grouped data object back into an array
        setTotalExpirationDateResults(Object.values(groupedData))

        const activeLicensesByYearAndMonth = [];
        for (let i = 0; i < _orderResults.length; i++) {
          const orderResult = _orderResults[i];
          
          Object.entries(orderResult.licenses_per_year_month).forEach(e => {
            const [year, monthly_results] = e;
            Object.entries(monthly_results).forEach(e => {
              const [month_name, value] = e;
              const existingYear = activeLicensesByYearAndMonth.find(res => res.name === year);

              if (!existingYear) {
                activeLicensesByYearAndMonth.push({
                  name: year,
                  entries: Object.entries(monthly_results).map(e => ({
                    name: e[0],
                    value: e[1]
                  }))
                })
              } else {
                  const monthEntry = existingYear.entries.find(e => e.name === month_name);
                  monthEntry.value += value;
              }
            })
          })
        }

        setByYearlyMonthlyResults(activeLicensesByYearAndMonth);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <Header />
      <MaxWidth>
        <PageTitleSlots title={getTranslation('reports.title')} />

        <GridContainer>
          <GridItem> <PieChart className='grid-item' label={'Licenses Per Order'} groups={totalResults} /> </GridItem>
          <GridItem> <PieChart className='grid-item' label={'Licenses Per Product'} groups={totalProductResults} /> </GridItem>
          <GridItem> <PieChart className='grid-item' label={'Licenses Per ExpirationDate'} groups={totalExpirationDateResults} /> </GridItem>
          <GridItem> <BarChart className='grid-item' label={'Licenses Per ExpirationDate'} groups={totalExpirationDateResults} /> </GridItem>
          <GridItem> <LineChart className='grid-item' label={'Licenses Per ExpirationDate'} groups={totalExpirationDateResults} /> </GridItem>
          <GridItem> <MultiLineChart className='grid-item' label={'Yearly Licenses'} groups={byYearlyMonthlyResults} /> </GridItem>
        </GridContainer>
      </MaxWidth>
    </>
  );
};

export default withRouter(ReportScreen);
