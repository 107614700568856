// LineChart.jsx

import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = (
  {
    groups,
    label,
  }
) => {

  const chartOptions = {
    chart: {
      id: 'basic-line',
      toolbar: {
        show: false,
        tools: {
          // download: `<img src="https://img.icons8.com/material-outlined/24/000000/download--v1.png" alt="Download" />`,
          download: false,
          selection: false, // Disable selection tool
          zoom: false,      // Disable zoom tool
          zoomin: false,    // Disable zoom-in button
          zoomout: false,   // Disable zoom-out button
          pan: false,       // Disable pan button
          reset: false,     // Disable reset button
        },
      },
      
      zoom: {
        enabled: false,    // Disable zoom functionality
    },
    },
    xaxis: {
      categories: groups.map(g => g.name)
    },
    title: {
      text: label,
      align: 'left',
    },
    stroke: {
      curve: 'smooth',
    },
  };

  const chartSeries = [
    {
      name: label,
      data: groups.map(g => g.value), // Data points for each month
    },
  ];

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        width={800}
      />
    </div>
  );
};

export default LineChart;
