import React from "react"
import {useTranslations} from "../../translations/useTranslations"
import {useParams, withRouter} from "react-router-dom"
import {H2, P} from "../../core/ui/typo"
import {Button} from "../../core/ui/fields"
import {LicensesWrapper, PageTitle} from "../licenses/licenses.view"
import RenderList from "../../core/modules/render-list"
import useFetchDeviceLicense from "../../apis/devicelicense"
import LoadingContainer from "../../core/modules/loading-container"
import OverlayPanel from "../../core/modules/overlay-panel"
import DeviceLicenseDetails from "../devicelicensedetails"

const Devices = ({fetchLicenseInformation}) => {
  const { id: licenseId } = useParams()

  const {
    data : devicesData,
    count,
    goToPage,
    nextPage,
    previousPage,
    fetchRequest,
    postRequest,
    putRequest,
    deleteRequest,
  } = useFetchDeviceLicense({licenseId: licenseId})
  const [isLoading, setIsLoading] = React.useState(true)

  const [overlay, setOverlay] = React.useState(false)
  const [overlayError, setOverlayError] = React.useState(false)
  const [overlayAction, setOverlayAction] = React.useState('')

  const {getTranslation} = useTranslations()

  const revokeLicense = (devicelicense_id, devicelicense) => {
    deleteRequest(devicelicense_id, devicelicense).then(() => {
      fetchLicenseInformation()
    })
  }

  const valid_from = new Date()
  const valid_to = new Date()
  valid_to.setFullYear(valid_to.getFullYear() + 1)
  const [deviceLicense, setDeviceLicense] = React.useState({
    ref_id: "",
    license: licenseId,
    device: "",
    valid_from: valid_from.toISOString(),
    valid_to: valid_to.toISOString(),
    uuid: '',
  })

  const updateUser = (key, value) => {
    const newState = {
      ...deviceLicense,
      [key]: value
    };
    setDeviceLicense(newState);
  }

  const addDeviceLicense = async () => {
    const payload = {
      ...deviceLicense,
      uuid: Date.now()
    }
    postRequest(payload).then((response) => {
      if (!response) {
        setOverlayError(true)
        fetchRequest()
        return
      }

      fetchLicenseInformation()

      setOverlay(false)
      setOverlayError(false)
    })
  }

  const updateDeviceLicense = async () => {
    const payload = {
      ...deviceLicense,
      uuid: Date.now()
    }
    putRequest(payload.id, payload).then((response) => {
      if (!response) {
        setOverlayError(true)
        fetchRequest()
        return
      }

      fetchLicenseInformation()

      setOverlay(false)
      setOverlayError(false)
    })
  }

  React.useEffect(() => {
    setIsLoading(false)
  }, [devicesData])

  return <LicensesWrapper>
    <PageTitle>
      <H2> {getTranslation("devices.devices")} </H2>
      <Button secondary onClick={() => {
        setOverlayAction("create");
        setOverlay(true)
      }}>
        {getTranslation("devices.button")}
      </Button>
    </PageTitle>
    <LoadingContainer isLoading={isLoading}>
      <RenderList
        data={devicesData.results}
        fields={["device.hwid"]}
        widths={[70]}
        hasOptions
        handleClick={(element) => {
          setDeviceLicense(element)
          setOverlayAction("update")
          setOverlay(true)
        }}
        handleOptionsClick={(action, item) => {
          if (action === "delete") {
            revokeLicense(item.id, item)
          }
        }}
        pagination={{
          'count': count,
          'amountOfItems': (devicesData?.results?.length || 0),
          'translations': {
            next: 'Next page',
            previous: 'Previous page',
            optionPrefix: 'Page '
          },
          'currentPage': 1,
          'handleNext': () => {
            nextPage()
          },
          'handlePrevious': () => {
            previousPage()
          },
          'handleGoToPage': (page) => {
            goToPage(page)
          },
          'doNotRequestNewPageOnUpdate': true
        }}
      />
    </LoadingContainer>

    <OverlayPanel
      contentWidth={650}
      isOpen={overlay}
      handleClick={() => {
        setOverlay(false)
        setOverlayError(false)
      }}
    >
      <DeviceLicenseDetails
        data={deviceLicense}
        handleChange={updateUser} />

      { (overlayAction === "update") && <Button onClick={updateDeviceLicense}> {getTranslation('devices.submit')} </Button> }
      { (overlayAction === "create") && <Button onClick={addDeviceLicense}> {getTranslation('devices.submit')} </Button> }

      {
        overlayError &&
        <P className='error'> Error while adding device </P>
      }
    </OverlayPanel>
  </LicensesWrapper>
}

export default withRouter(Devices);