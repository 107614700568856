import React from 'react'

// auth
import { useAuth } from '../../auth/auth-context-wrapper'

// Core
import CenterComponent from '../../core/modules/center'
import Panel from '../../core/modules/panel'
import { Field, Button } from '../../core/ui/fields'
import { H1, P } from '../../core/ui/typo'
import { useTranslations } from '../../translations/useTranslations'

// View
import RecoverWrapper from './recover.view'

// Component
const RecoverComponent = () => {
  // translations
  const { getTranslation } = useTranslations()

  // Auth helper
  const { recoverPassword, authError } = useAuth()

  // Local state
  const [loginFields, setFields] = React.useState({
    email: ''
  })
  const [sentMessage, showSentMessage] = React.useState(false)
  const [error, setError] = React.useState(false)

  // Handle field values
  const handleFieldChange = (key, value) => {
    setFields({
      ...loginFields,
      [key]: value
    })
  }

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      !loginFields.email ||
      loginFields.email.length === 0
    ) {
      setError(true)
    } else {
      setError(false)
      showSentMessage(true)
      recoverPassword(loginFields)
    }
  }

  // Render component
  return <RecoverWrapper>
    <CenterComponent>
      {!sentMessage && <Panel>
        <H1> { getTranslation('resetpassword.title') } </H1>
        <P> { getTranslation('resetpassword.desc') } </P>
        
        <form onSubmit={handleSubmit}>
          <Field
            type='string'
            value={loginFields.email || ''}
            placeholder={getTranslation('login.recoverEmail')}
            onChange={e => handleFieldChange('email', e.target.value)}
          />
          <Button>{getTranslation('login.button')}</Button>
        </form>
      </Panel>}
      {
        sentMessage && <div className='sent-message-wrapper'>
          {getTranslation('login.sentMessage')}
        </div>
      }
    </CenterComponent>
  </RecoverWrapper>
}

export default RecoverComponent