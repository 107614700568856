import React, { useEffect } from 'react';

// APIs
import useProducts from '../../apis/products';
import useLicenses, { getFetchLicensesEndpoint } from '../../apis/licenses';
import { useAppState } from '../../apis/utils/app-state';
import { fetchApi, postApi } from '../../apis/utils/fetch';

// core
import { useTranslations } from '../../translations/useTranslations';
import { Button, Field, FieldWithLabel, SelectArea, SelectBox } from '../../core/ui/fields';
import { H2 } from '../../core/ui/typo';
import OverlayPanel from '../../core/modules/overlay-panel';
import SelectOptions from '../../core/modules/select-chip';
import RenderListFilters from '../../core/modules/render-list-with-filters';
import { OverlayWrapperStyling, LicensesWrapper, PageTitle } from './licenses.view';
import { PageTitleWrapper } from './licenses.view';
import { getProduct } from './licenses.utils';
import useSKUSelection from '../../apis/skuselection';


const processLicenses = (licenses = [], products = []) => {
  const newLicenses = [...licenses];
  newLicenses.forEach(license => {
    if (products && products.results && license.product) {
      const foundProduct = getProduct(license.product, products.results);

      if (foundProduct && foundProduct.name) {
        license.product = foundProduct.name;
      }
    }
  });

  return newLicenses;
};

// Logical component
const Licenses = ({ order, fetchOrder, orderItems }) => {
  // Data
  const { data } = useProducts();
  const { forceResetEndpoints } = useAppState();

  // We might need another state variable for this.
  const [filterOptions, setFilterOptions] = React.useState([
    {
      field: 'valid_to',
      options: [
        {
          field: 'Default',
        },
        {
          field: 'Valid',
        },
        
        {
          field: 'Expired',
        },
      ],
    },
  ]);

  const [activeFilter, setActiveFilter] = React.useState(null); // Default to 'Valid'

  const getSelectedFilters = () => {
    const filters = filterOptions
      .filter(f => f.options.find(filter => filter.selected))
      .map(fields => fields.options)
      .flat()
      .filter(filterOption => filterOption.selected);

    return filters;
  };

  let { data: licenses, count, goToPage, nextPage, previousPage, deleteRequest, putRequest, executeFilter } = useLicenses({
    orderid: order.id,
    filter: activeFilter, // Pass the active filter dynamically
  });

  const skuData = useSKUSelection();

  // Translations
  const { getTranslation } = useTranslations();
  const errorMessage = getTranslation('licenses.noLicenses');
  const buttonText = getTranslation('licenses.addLicense');
  const licenseText = getTranslation('order.licenses');
  const modifyLicenseText = getTranslation('licenses.modifyLicense');
  const deleteLicenseText = getTranslation('licenses.deleteLicense');

  // Order information.
  const hasLicenses = licenses.results && licenses.results.length > 0;
  const processedLicenses = processLicenses(licenses.results, data.results);

  // State for adding a license
  const [overlay, setOverlay] = React.useState(false);
  const [error, setError] = React.useState(false);

  // Overlay for Licenses
  const [licenseOverlay, setLicenseOverlay] = React.useState(false);
  const [errorLicense, setErrorLicense] = React.useState(false);
  const [licenseItem, setLicenseItem] = React.useState({});

  const [orderItemState, setOrderItemState] = React.useState({
    order: order.id,
    product: null,
    sku: null,
    quantity: 0,
  });
  const [skuList, setSkuList] = React.useState([]);

  React.useEffect(() => {
    skuData.fetchRequest();
    setOrderItemState({
      ...orderItemState,
      order: order.id,
    });
  }, [order.id]);

  const flushState = () => {
    forceResetEndpoints(['orders', 'licenses', `licenses-orderid-${orderItemState.order}`]);
  };

  const handleClick = () => {
    orderItems
      .postRequest(orderItemState)
      .then(payload => {
        if (payload?.keys) {
          setOverlay(false);
        }
      })
      .then(() => {
        flushState();
      });
  };

  const handleUpdate = (type, id) => {
    setOrderItemState({
      ...orderItemState,
      [type]: id,
    });
    if (type === 'product') {
      const newSKUList =
        skuData?.data?.results?.filter &&
        skuData?.data?.results.filter(item => {
          return item.id === id;
        })[0];
      if (newSKUList && newSKUList.skus) {
        setSkuList(newSKUList.skus);
      } else {
        setSkuList([]);
      }
    }
  };

  const handleLicensePut = () => {
    putRequest(licenseItem.id, licenseItem);
    setLicenseOverlay(false);
    flushState();
  };

  const handleLicenseUpdate = (key, value) => {
    setLicenseItem({
      ...licenseItem,
      [key]: value,
    });
    flushState();
  };

  const deleteLicense = (id, item) => {
    deleteRequest(id, item);
    flushState();
  };

  const getPageTitle = () => {
    let title = licenseText;
    if (licenses && licenses.count) {
      title = `${title} (${licenses.count})`;
    }

    return title;
  };

  useEffect(() => {}, [filterOptions]);

  const normalFields = [
    {
      field: 'product.name',
    },
    {
      field: 'created_at',
    },
    {
      field: 'ref_id',
    },
    {
      field: 'activation_count',
    },

    {
      field: 'device_count',
    },
  ];

  const exportCSV = () => {
    const endpoint = getFetchLicensesEndpoint(order.id, activeFilter, 'export');

    fetchApi(endpoint).then(res => {
      res.blob().then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        
        link.download = `${order.company}_${new Date().toLocaleDateString()}_${activeFilter ?? 'all'}_export.csv`; // Replace with the file name and extension
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  React.useEffect(() => {
    // Re-fetch licenses when activeFilter changes
    // You might already be doing this via useLicenses
  }, [activeFilter]);

  const setActiveFilterHandler = (value) => {
    const updatedFilterOptions = filterOptions.map(fg => ({
      ...fg,
      options: fg.options.map(option => ({
        ...option,
        selected: option.field === value // Select the new filter
      }))
    }));

    setFilterOptions(updatedFilterOptions);
    setActiveFilter(value); // Update active filter state
    executeFilter({
      name: value.toLowerCase(),
      value: true
    })
  };


  // Render actual list.
  return (
    <LicensesWrapper>
      <PageTitleWrapper title={getPageTitle()}>
        <SelectArea defaultValue={activeFilter} onChange={e => setActiveFilterHandler(e.target.value)}>
          {filterOptions &&
            filterOptions.map((filterGroup, groupIndex) =>
              filterGroup.options.map((option, optionIndex) => (
                <option key={`${groupIndex}-${optionIndex}`} value={option.field} selected={option.selected}>
                  {option.field}
                </option>
              )),
            )}
        </SelectArea>

        <Button secondary onClick={exportCSV} style={{ width: 'auto' }}>
          {getTranslation('list.export_csv')}
        </Button>

        <Button style={{ width: 'auto' }} primary onClick={() => setOverlay(true)}>
          {getTranslation('licenses.addLicense')}
        </Button>
      </PageTitleWrapper>

      {!hasLicenses && <div className='message-wrapper'>{errorMessage}</div>}
      {hasLicenses && (
        <RenderListFilters
          data={processedLicenses}
          fields={[...normalFields, ...filterOptions]}
          widths={[12, 16, 30, 12, 12, 18]}
          handleClick={item => {}}
          hasDelete
          hasEdit
          hasCopy
          handleOptionsClick={(action, item) => {
            if (action === 'delete') {
              deleteLicense(item.id, item);
            }

            if (action === 'undelete') {
              postApi(`v1/licenses/${item.id}/recover/`).then(flushState());
            }

            if (action === 'edit') {
              setLicenseItem(item);
              setLicenseOverlay(true);
            }

            if (action === 'copy') {
              navigator.clipboard.writeText(item.ref_id);
            }
          }}
          pagination={{
            count: count,
            amountOfItems: licenses?.results?.length || 0,
            translations: {
              next: 'Next page',
              previous: 'Previous page',
              optionPrefix: 'Page ',
            },
            currentPage: 1,
            handleNext: () => {
              nextPage();
            },
            handlePrevious: () => {
              previousPage();
            },
            handleGoToPage: page => {
              goToPage(page, activeFilter ? activeFilter : '');
            },
            doNotRequestNewPageOnUpdate: true,
          }}
        />
      )}

      <OverlayPanel
        contentWidth={650}
        isOpen={overlay}
        handleClick={() => {
          setOverlay(false);
          setError(false);
        }}
      >
        <OverlayWrapperStyling>
          <SelectOptions handleSelected={id => handleUpdate('product', id)} options={data.results} label={getTranslation('product.productsList')} labelKey='name' idKey='id' />

          {skuList && skuList.length > 0 && (
            <SelectOptions handleSelected={id => handleUpdate('sku', id)} options={skuList} label={getTranslation('product.licensesList')} labelKey='name' idKey='id' />
          )}
          <Field value={orderItemState.quantity || ''} onChange={e => handleUpdate('quantity', e.target.value)} placeholder={getTranslation('order.quantity')} />

          <Button onClick={() => handleClick()}>{buttonText}</Button>

          {error && <div className='error'>{getTranslation('license.error')}</div>}
        </OverlayWrapperStyling>
      </OverlayPanel>

      <OverlayPanel
        contentWidth={650}
        isOpen={licenseOverlay}
        handleClick={() => {
          setLicenseOverlay(false);
          setErrorLicense(false);
        }}
      >
        <OverlayWrapperStyling>
          <b> Activation Limit: </b>
          <Field value={licenseItem.activation_limit ?? ''} onChange={e => handleLicenseUpdate('activation_limit', e.target.value)} placeholder={'placeholder'} />

          <b>Device Limit: </b>
          <Field value={licenseItem.device_limit ?? ''} onChange={e => handleLicenseUpdate('device_limit', e.target.value)} placeholder={'placeholder'} />

          <Field value={licenseItem.device_id ?? ''} onChange={e => handleLicenseUpdate('device_id', e.target.value)} placeholder={' Device ID'} />

          <Button onClick={() => handleLicensePut()}>{modifyLicenseText}</Button>

          {licenseItem.deleted_at !== null && (
            <Button secondary onClick={() => handleLicensePut()}>
              {deleteLicenseText}
            </Button>
          )}

          {errorLicense && <div className='error'>{getTranslation('license.error')}</div>}
        </OverlayWrapperStyling>
      </OverlayPanel>
    </LicensesWrapper>
  );
};
export default Licenses;
