import React from 'react'
import useFetchAppState from './utils/fetch-app-state'
import { useHistory } from "react-router-dom";

// Hook
const useFetchOrders = (orderId) => {
  const [key] = React.useState(orderId ? `orderid-${orderId}` : 'orders')
  const [endpoint] = React.useState(orderId ? `v1/orders/${orderId}/` : 'v1/orders/')
  const history = useHistory()
  const payload = useFetchAppState(key, endpoint)

  if (payload.unAuthenticated) {
    history.push("/logout")
  }

  return payload
}
export default useFetchOrders
