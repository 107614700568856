import styled from 'styled-components'

const MaxWidthContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 30px;

  > div {
    position: relative;
    width: 100%;
  }
`

const maxWidthModule = ({ children }) => {
  return <MaxWidthContent>
    <div>
      {children}
    </div>
  </MaxWidthContent>

}
export default maxWidthModule