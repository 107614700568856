import styled from 'styled-components'

const HeaderContainer = styled.div`
  height: 100px;
  background: #1f1c3c;
  color: #fff;

  a {
    color: #fff;
    height: 100px;
    line-height: 100px;
    display: inline-block;
  }

  .logoHolder {
    position: absolute;
    top: 17px;
    left: 0;

    img {
      max-height: 55px;
    }
  }
  
  .navigationContainer {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;

    a {
      padding: 0 0px;
      font-size: 17px;
      font-weight: 600;
      margin: 0 20px;

      &.activeMenu, &:hover {
        border-bottom: 4px solid #c7a54a;
        height: 96px;
      }
    }
  }
`
export default HeaderContainer