import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({
  groups,
  label
}) => {


  const chartOptions = {
    labels: groups && groups.map(g => g.name),
    height: '2000px',
    tools: {
      show: false,
    },
    title: {
      text: label,
      align: 'left',
    },
    legend: {
      show: false,
      position: 'bottom',
    },
  };

  const chartSeries = groups.map(g => g.value)

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="pie"
        width={800}
      />
    </div>
  );
};

export default PieChart;
