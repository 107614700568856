import React from 'react'
import styled from 'styled-components'

// Core components
import { H3 } from '../../core/ui/typo'
import { Field } from '../../core/ui/fields'
import { useTranslations } from '../../translations/useTranslations'

// Styled components
const DetailsContainer = styled.div`
  > div {
    padding: 12px 0;
  }

  .inputDetailWrapper {
    display: inline-block;
    height:60px;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    margin: 6px 0;

    &:nth-child(2), &:nth-child(5),
    &:nth-child(7), &:nth-child(9) {
      padding-right: 10px;
    }

    &:nth-child(3), &:nth-child(6),
    &:nth-child(8), &:nth-child(10) {
      padding-left: 10px;
    }
  }

  .contactDetails {
    .inputDetailWrapper {
      &:nth-child(4) {
        width: 100%;
      }
      &:nth-child(7) {
        width: 70%;
      }

      &:nth-child(8) {
        width: 30%;
      }
    }
  }

  ${
    props => props.secondary
      ? `
        background: rgba(0,0,0,0.06);
        padding: 22px;
        color: #353535;

        input {
          background: #fff;
          color: #353535;

          &::placeholder {
            color: rgba(0,0,0,.5);
          }
        }
      `
      : ''
  }
`

// Order details component
const OrderDetails = ({
  data = {},
  secondary,
  handleChange
}) => {
  const { getTranslation } = useTranslations()

  return <DetailsContainer secondary={secondary}>
    <div className='orderDetails'>
      <H3> {getTranslation('order.orderTitle')} </H3>
      <div className='inputDetailWrapper'>
        <Field
          value={data.ref_id}
          onChange={e => handleChange('ref_id', e.target.value)}
          placeholder={getTranslation('order.id')} />
      </div>
    </div>

    <div className='contactDetails'>
      <H3> {getTranslation('order.contactTitle')} </H3>
      <div className='inputDetailWrapper'>
        <Field
          value={data.first_name}
          onChange={e => handleChange('first_name', e.target.value)}
          placeholder={getTranslation('order.first_name')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.last_name}
          onChange={e => handleChange('last_name', e.target.value)}
          placeholder={getTranslation('order.last_name')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.company}
          onChange={e => handleChange('company', e.target.value)}
          placeholder={getTranslation('order.company')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.phone}
          onChange={e => handleChange('phone', e.target.value)}
          placeholder={getTranslation('order.phone')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.email}
          onChange={e => handleChange('email', e.target.value)}
          placeholder={getTranslation('order.email')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.address}
          onChange={e => handleChange('address', e.target.value)}
          placeholder={getTranslation('order.address')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.zip}
          onChange={e => handleChange('zip', e.target.value)}
          placeholder={getTranslation('order.zip')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.state}
          onChange={e => handleChange('state', e.target.value)}
          placeholder={getTranslation('order.state')} />
      </div>
      <div className='inputDetailWrapper'>
        <Field
          value={data.country}
          onChange={e => handleChange('country', e.target.value)}
          placeholder={getTranslation('order.country')} />
      </div>
    </div>
  </DetailsContainer>
}
export default OrderDetails
