import React from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth } from '../auth/auth-context-wrapper'

// Screen component
const LogOut = ({ }) => {
  const { logout } = useAuth()

  React.useEffect(() => {
    logout()
  }, [])

  // Render the component
  return <>
  </>
}

export default withRouter(LogOut)
