import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

// Block the scroll on the page
const CutScroll = createGlobalStyle`
  body, html, #root {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }
`

// Overlay backdrop
const OverlayBackground = styled.div`
  background: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 0;
  overflow: hidden;
  overflow-y: scroll;
`

// Overlay Module
const OverlayModule = ({ children, isOpen, handleClick }) => {
  if (!isOpen) {
    return null
  }

  return <>
    {/* <CutScroll /> */}
    <OverlayBackground onClick={handleClick} />
    <OverlayWrapper>
      {children}
    </OverlayWrapper>
  </>
}

export default OverlayModule