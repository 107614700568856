import React from 'react'
import styled from 'styled-components'

import { Label } from '../../core/ui/fields'

// Styled Option
const ChipOption = styled.div`
  padding: 14px 22px;
  background: #1f1c3c;
  border-radius: 6px;
  margin: 10px 8px 0 0;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: rgba(0,0,0,0.6);
    background: #fff;
  }

  ${props => props.activated ? ' color: rgba(0,0,0,0.6); background: #fff;' : ''}
`

const SelectContainer = styled.div`
  padding: 0 0 22px 0;
`

const SelectOptions = ({
  options,
  label,
  labelKey,
  idKey,
  handleSelected
}) => {
  const [select, setSelected] = React.useState(null)

  if (!options || options.length === 0 || !options.map) {
    return null
  }

  return <SelectContainer>
    <Label> { label }: </Label>
    <div>
      {
        options && options.map((option, index) => <ChipOption
          key={`chip-option-${index}`}
          activated={select === index}
          onClick={() => {
          const returnValue = idKey && option[idKey] ? option[idKey] : option

          handleSelected(returnValue)
          setSelected(index)
        }}>
          {option[labelKey]}
        </ChipOption>)
      }
    </div>
  </SelectContainer>
}
export default SelectOptions