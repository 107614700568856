import React from 'react'

import {useTranslations} from '../../translations/useTranslations'
import LoadingWrapper from './loading-wrapper'

const LoadingContainer = ({ children, isLoading, isError }) => {
  const { getTranslation } = useTranslations()
  if (isLoading || isError) {
    return <LoadingWrapper>
      {isError && <p> {getTranslation('error')} </p>}
    </LoadingWrapper>
  }

  return <> {children} </>
}
export default LoadingContainer