import React from 'react'
import styled from 'styled-components'

// Styled components
const CenterWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;

  > .contentWrapper {
    display: table-cell;
    vertical-align: middle;

    > .centerContent {
      max-width: ${props => props.contentWidth}px;
      margin: 0 auto;
    }
  }
`

// Wrapping component
const CenterComponent = ({ children, contentWidth = 500 }) => {
  return <CenterWrapper contentWidth={contentWidth}>
    <div className='contentWrapper'>
      <div className='centerContent'>
       {children}
      </div>
    </div>
  </CenterWrapper>
}
export default CenterComponent