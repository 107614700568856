import { fetchApi } from '../../apis/utils/fetch';

function searchQuery(data = [], query = '') {
  return searchLocalQuery(data, query);
}

export const searchAPI = async (
  query = '',
  apiOptions = {
    route: '',
    columns: [],
  },
  data = [],
) => {
  const { route, columns } = apiOptions;

  let _results = [];

  if (query.length > 0) {
    if (route && route !== '') {
      for (const column of columns) {
        const results = await fetchApi(`${route}?column=${column}&value=${query}`);
        _results = [
          ..._results,
          ...results
        ]
      }
    } else {
      //  console.warn('User opted to use the API functionality however the route is invalid!');
    }
  } else {
    return data;
  }

  const searchResults = []
  for (let i = 0; i < _results.length; i++) {
    const ress = _results[i]
    if (!searchResults.find(r => r.id === ress.id)) {
      searchResults.push(ress);
    }
  }

  return searchResults
};

const searchLocalQuery = (data = [], query = '') => {
  if (!data || data.length === 0) {
    return { searchedData: [] };
  }
  const originalData = [...data];
  if (originalData.length > 0 && query && query.length > 0) {
    const newData = originalData.filter(item => {
      // Register if the object contains a value we might be looking for.
      let hasDataWeNeed = false;

      // Search through the object.
      for (const [key, value] of Object.entries(item)) {
        // No need to continue searching if the value is already true.
        if (!hasDataWeNeed) {
          const processedValue = value && typeof value === 'string' && value.toLowerCase();
          const processedQuery = query && typeof query === 'string' && query.toLowerCase();

          if (processedValue) {
            hasDataWeNeed = processedValue.includes(processedQuery);
          }
        }
      }

      // Return a boolean, so the .filter method returns the whole object.
      return hasDataWeNeed;
    });

    return { searchedData: newData };
  }
  return { searchedData: originalData };
};

export default searchQuery;
