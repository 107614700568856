import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    color: #1b1d21;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  div {
    display: block;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
 
export default GlobalStyle;