import React from 'react'

// Routing
import { withRouter } from 'react-router-dom'

// auth
import { useAuth } from '../../auth/auth-context-wrapper'

// Core
import CenterComponent from '../../core/modules/center'
import Panel from '../../core/modules/panel'
import { Field, Button } from '../../core/ui/fields'
import { H1, P } from '../../core/ui/typo'
import { useTranslations } from '../../translations/useTranslations'

// View
import LoginWrapper from './login.view'

// Component
const LoginComponent = ({ history }) => {
  // translations
  const { getTranslation } = useTranslations()

  // Auth helper
  const { authenticate, authError } = useAuth()

  // Local state
  const [loginFields, setFields] = React.useState({})
  const [error, setError] = React.useState(false)

  // Handle field values
  const handleFieldChange = (key, value) => {
    setFields({
      ...loginFields,
      [key]: value
    })
  }

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      !loginFields.username ||
      !loginFields.password ||
      loginFields.username.length === 0 ||
      loginFields.password.length === 0
    ) {
      setError(true)
    } else {
      setError(false)
      authenticate(loginFields)
    }
  }

  // Render component
  return <LoginWrapper>
    <CenterComponent>
      <Panel>
        <H1> { getTranslation('login.title') } </H1>
        <P> { getTranslation('login.desc') } </P>
        
        <form onSubmit={handleSubmit}>
          <Field
            type='string'
            value={loginFields.username || ''}
            placeholder={getTranslation('login.username')}
            onChange={e => handleFieldChange('username', e.target.value)}
          />
          <Field
            type='password'
            value={loginFields.password || ''}
            placeholder={getTranslation('login.password')}
            onChange={e => handleFieldChange('password', e.target.value)}
          />
          <Button>{getTranslation('login.button')}</Button>
          <div className='recover-account' onClick={() => history.push('/recover')}>
            {getTranslation('login.recover')}
          </div>
        </form>
        {
          error && <P className='error'> {getTranslation('login.error')} </P>
        }

        {
          authError && <P className='error'> {getTranslation('login.validAccount')} </P>
        }
      </Panel>
    </CenterComponent>
  </LoginWrapper>
}

export default withRouter(LoginComponent)