// MultiLineChart.jsx

import React from 'react';
import Chart from 'react-apexcharts';

const MultiLineChart = ({ groups, label }) => {
  const chartOptions = {
    chart: {
      id: 'multi-line-chart',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: groups[0]?.entries?.map(e => e.name), // x-axis labels (months)
    },
    stroke: {
    },
    title: {
      text: label,
      align: 'left',
    },
  };

  const chartSeries = [];
  if (groups) {
    groups.forEach(g => {
      const values = g.entries.map(g => g.value);
      chartSeries.push({
        name: g.name,
        data: values,
      });
    });
  }

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type='line' width={800} />
    </div>
  );
};

export default MultiLineChart;
