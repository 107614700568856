import React from 'react'
import useFetchAppState from './utils/fetch-app-state'
import { useHistory } from "react-router-dom";

// Hook
const useFetchProducts = (productid) => {
  const [key] = React.useState(productid ? `product-${productid}` : 'products')
  const [endpoint] = React.useState(productid ? `v1/products/${productid}/` : 'v1/products/')
  const history = useHistory()
  const payload = useFetchAppState(key, endpoint)

  if (payload.unAuthenticated) {
    history.push("/logout")
  }

  return payload
}
export default useFetchProducts