import React from 'react'

// 3rd party dependencies
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'

// Context provider
import TranslationsProvider from './translations/useTranslations'
import AuthProvider, { useAuth } from './auth/auth-context-wrapper'
import GlobalStateProvider from './apis/utils/app-state'

// Screens
import LoginScreen from './screens/Login'
import OrdersScreen from './screens/Orders'
import ProductsScreen from './screens/Products'
import ManageProductScreen from './screens/ManageProduct'
import ManageOrderScreen from './screens/ManageOrder'
import AdminScreen from './screens/Admins'
import ReportScreen from './screens/Reports'
import LicensesScreen from './screens/Licenses'
import ManageLicenseScreen from './screens/ManageLicense'
import Logout from './screens/Logout'
import RecoverScreen from './screens/Recover'
import RecoverValidate from './screens/RecoverValidate'

// Styling
import GlobalStyling from './core/global-style.js'

// Text
import translations from './translations/text'

// Application Wrapper
// Routing, components etc
const App = withRouter(({ location, history }) => {
  const { user, logout } = useAuth()
  const isValidUser = user && user["id"] && user["id"] >= 0

  const IsAnAuthenticatedPath = ({ children }) => {
    React.useEffect(() => {
      if(!isValidUser) {
        logout()
        history.push('/')
      }
    }, [isValidUser])

    if (!isValidUser) {
      return <>  </>
    }
    if (isValidUser) {
      return <> {children} </>
    }
  }

  // Validate the path is correct
  // Redirect if a user is on any other path when logged off.
  React.useEffect(() => {
    // User is logged in and on the wrong path
    if ((isValidUser === 1 || isValidUser === true) && location && (location.pathname === '/')) {
      history.push('/orders')
    }
  }, [isValidUser, location, history])

  // Render the router
  return <Switch>
    <Route exact path="/">
      <LoginScreen />
    </Route>
    <Route exact path="/login">
      <LoginScreen />
    </Route>
    <Route exact path="/recover">
      <RecoverScreen />
    </Route>
    <Route exact path="/recover/:hash">
      <RecoverValidate />
    </Route>
    <Route exact path="/orders">
      <IsAnAuthenticatedPath>
        <OrdersScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/order/:id">
      <IsAnAuthenticatedPath>
        <ManageOrderScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/products">
      <IsAnAuthenticatedPath>
        <ProductsScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/product/:id">
      <IsAnAuthenticatedPath>
        <ManageProductScreen />
      </IsAnAuthenticatedPath>
    </Route>

    <Route exact path="/reports">
      <IsAnAuthenticatedPath>
        <ReportScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/admins">
      <IsAnAuthenticatedPath>
        <AdminScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/licenses">
      <IsAnAuthenticatedPath>
        <LicensesScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/license/:id">
      <IsAnAuthenticatedPath>
        <ManageLicenseScreen />
      </IsAnAuthenticatedPath>
    </Route>
    <Route exact path="/logout">
      <IsAnAuthenticatedPath>
        <Logout />
      </IsAnAuthenticatedPath>
    </Route>
    <Route />
  </Switch>
})

// Wrapper, used for context and global or cascading styling.
function AppWrapper() {
  return <>
    <GlobalStyling />
    <TranslationsProvider defaultTranslations={translations}>
      <AuthProvider>
        <GlobalStateProvider
          CSRFTokenEndpoint='csrftoken'
          CSRFTokenHeader='X-CSRFToken'
        >
          <Router>
            <App />
          </Router>
        </GlobalStateProvider>
      </AuthProvider>
    </TranslationsProvider>
  </>
}

export default AppWrapper;
