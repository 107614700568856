import React from 'react'
import useFetchAppState from './utils/fetch-app-state'
import { useHistory } from "react-router-dom";

// Hook
const useFetchOrderItems = (order) => {
  const [key] = React.useState('orderitems')
  const [endpoint] = React.useState('v1/orderitems/?order=' + order)
  const history = useHistory()
  const payload = useFetchAppState(key, endpoint)

  if (payload.unAuthenticated) {
    history.push("/logout")
  }

  return payload
}
export default useFetchOrderItems
