import React from 'react'

// auth
// import { useAuth } from '../../auth/auth-context-wrapper'
import { withRouter, useParams } from 'react-router-dom'
import LoadingWrapper from '../../core/modules/loading-wrapper'
import { postApi } from '../../apis/utils/fetch'

// Core
import CenterComponent from '../../core/modules/center'
import Panel from '../../core/modules/panel'
import { Field, Button } from '../../core/ui/fields'
import { H1, P } from '../../core/ui/typo'
import { useTranslations } from '../../translations/useTranslations'

// View
import RecoverWrapper from './recover.view'

// Component
const RecoverComponent = ({ history }) => {
  // state
  const [newPassword, setpassword] = React.useState({
    password: '',
    confirmPassword: ''
  })
  const [error, showError] = React.useState(false)
  const [tokenError, showTokenError] = React.useState(false)
  const [showFields, setShowFields] = React.useState(false)
  const [submitError, showSubmitError] = React.useState(false)

  // translations
  const { getTranslation } = useTranslations()
  const { hash } = useParams()

  // Change handler
  const handleChange = (field, value) => {
    setpassword({
      ...newPassword,
      [field]: value
    })
  }

  // Submit passwords
  const handleSubmit = () => {
    if (
      newPassword.password.length > 3 && 
      newPassword.confirmPassword.length > 3 &&
      newPassword.confirmPassword === newPassword.password
    ) {
      postApi('reset-password/confirm/', {token: hash, password: newPassword.password }).then(response => {
        if (response.status === "OK") {
          history.push('/')
        } else {
          showSubmitError(true)
        }
      }).catch(() => showSubmitError(true))
    } else if (newPassword.confirmPassword !== newPassword.password) {
      showError(true)
    }
  }

  // Validate hash on mount
  React.useEffect(() => {
    postApi('reset-password/validate_token/', {token: hash}).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        setShowFields(true)
      }
    }).catch(() => showTokenError(true))
  }, [])

  // Render component
  return <RecoverWrapper>
    <CenterComponent>
      <Panel>
        <H1> { getTranslation('validate.title') } </H1>
        <P> { getTranslation('validate.desc') } </P>
        
        {
          !tokenError && showFields && <LoadingWrapper />
        }

        {
          /*showFields && */<div>
            <Field
              type='string'
              value={newPassword.password || ''}
              placeholder={getTranslation('validate.password')}
              onChange={e => handleChange('password', e.target.value)}
            />
            <Field
              type='string'
              value={newPassword.confirmPassword || ''}
              placeholder={getTranslation('validate.confirmPassword')}
              onChange={e => handleChange('confirmPassword', e.target.value)}
            />
            <Button onClick={handleSubmit} >{getTranslation('login.button')}</Button>
          </div>
        }

        {error && <div style={{ fontWeight: 'bold', color: 'red' }}>
          <br /> {getTranslation('validate.passwordNotSame')}
        </div>}

        {tokenError && <div style={{ fontWeight: 'bold', color: 'red' }}>
          <br /> {getTranslation('validate.tokenError')}
        </div>}

        {submitError && <div style={{ fontWeight: 'bold', color: 'red' }}>
          <br /> {getTranslation('validate.submitError')}
        </div>}
      </Panel>
    </CenterComponent>
  </RecoverWrapper>
}

export default withRouter(RecoverComponent)