import React from 'react'
import styled from 'styled-components'

import searchIcon from '../assets/search.png'

// Core imports
import { Field } from '../ui/fields'

// Styling
const SearchModuleStyling = styled.div`
  position: relative;
  margin: 12px 0;
  width: 100%;

  input {
    background: rgba(0,0,0,0.07);
    padding-left: 50px;
    color: #1e1e1e;

    &::placeholder {
      color: rgba(0,0,0,0.4);
    }
  }

  img {
    position: absolute;
    top: 30px;
    left: 16px;
    width: 20px;
  }
`

// Component
const SearchModule = ({value, onChange, placeholder, style}) => {
  return <SearchModuleStyling style={style}>
    <Field
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
    <img src={searchIcon} alt='Search' />
  </SearchModuleStyling>
}
export default SearchModule