import React from 'react'
import { withRouter, useParams } from 'react-router-dom'
import styled from 'styled-components'

import useProducts from '../apis/products'
import {useProductSkus} from '../apis/skus'

// Assests
import backbutton from '../core/assets/backbutton.png'

// Generic components
import Header from '../components/header'
import MaxWidth from '../core/modules/max-width-content'
import PageTitle from '../core/modules/page-title'
import { useTranslations } from '../translations/useTranslations'
import ProductDetails from '../components/products/productDetails'
import Footer from '../components/footer'
import LoadingContainer from '../core/modules/loading-container'
import RenderList from '../core/modules/render-list'
import OverlayPanel from '../core/modules/overlay-panel'
import { H3 } from '../core/ui/typo'
import { FieldWithLabel, TextFieldWithLabel, Button } from '../core/ui/fields'
import SearchModule from '../core/modules/search'
import searchQuery from '../core/utils/searchQuery'

// Back button
const SkuButtonContainer = styled.div`
  height: 40px;
  clear: both;
  margin-top: 40px;

  > button {
    float: right;
    width: auto;
  }
`

const BackButton = styled.div`
  cursor: pointer;
  position: relative;
  top: 52px;
  left: 0;
  color: #9f9f9f;
  z-index: 999;
  display: inline;

  img {
    margin-right: 8px;
    height: 13px;
  }

  &:hover {
    opacity: 0.7;
  }
`
// Add SKU panel
const AddSkuContainer = styled.div`
  padding: 0 0 12px 0;

  h3 {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 12px;
    font-size: 22px;
  }

  .inputDetailWrapper {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    margin: 6px 0;
    padding: 0 4px;

    &:nth-child(2), &:nth-child(4) {
      padding-right: 10px;
    }

    &:nth-child(3), &:nth-child(5) {
      padding-left: 10px;
    }

  .inputCombiWrapper {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    margin: 0 0;

    &:nth-child(3), &:nth-child(5) {
      padding-right: 10px;
    }

    &:nth-child(2), &:nth-child(4) {
      padding-left: 10px;
    }
  }
`

const ErrorWrapper = styled.div`
  .error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
  }
`

// Reusable UI
const SKUOverlay = ({
  data,
  isNew,
  handleAdd,
  inlineError,
  handleUpdate,
  product
}) => {
  // translations.
  const { getTranslation } = useTranslations()

  // Mappings.
  const actionText = isNew ? getTranslation('sku.add') : getTranslation('sku.edit')

  // Return generic view.
  return <ErrorWrapper>
    <AddSkuContainer>
      <H3> { actionText } </H3>
      <FieldWithLabel
        value={data.name}
        id='sku-name'
        label={getTranslation('sku.name')}
        handleChange={e => handleUpdate('name', e.target.value)}
        placeholder={getTranslation('sku.name')}
      />

      <TextFieldWithLabel
        value={data.description}
        id='sku-description'
        label={getTranslation('sku.description')}
        handleChange={e => handleUpdate('description', e.target.value)}
        placeholder={getTranslation('sku.description')}
      />

      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={data.license.duration}
          id='sku-duration'
          label={getTranslation('sku.duration')}
          handleChange={e => handleUpdate('duration', e.target.value)}
          placeholder={getTranslation('sku.duration')}
        />
      </div>
      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={data.license.device_limit}
          id='sku-activeDevices'
          label={getTranslation('sku.activeDevices')}
          handleChange={e => handleUpdate('device_limit', e.target.value)}
          placeholder={getTranslation('sku.activeDevices')}
        />
      </div>

      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={data.license.activation_limit}
          id='sku-activePerAll'
          label={getTranslation('sku.activePerAll')}
          handleChange={e => handleUpdate('activation_limit', e.target.value)}
          placeholder={getTranslation('sku.activePerAll')}
        />
      </div>
      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={data.license.device_activation_limit}
          id='sku-activePerDevice'
          label={getTranslation('sku.activePerDevice')}
          handleChange={e => handleUpdate('device_activation_limit', e.target.value)}
          placeholder={getTranslation('sku.activePerDevice')}
        />
      </div>

    </AddSkuContainer>
    <Button onClick={() => handleAdd()}>
      { actionText }
    </Button>
    {
      inlineError && <p className='error'>
        {getTranslation('license.inlineError')}
      </p>
    }
  </ErrorWrapper>
}

const defaultSku = {
  ref_id: '',
  description: '',
  name: '',
  license: {
    ref_id: '',
    duration: '',
    activation_limit: '',
    device_limit: '',
    device_activation_limit: '',
  },
  uuid: ''
}

// Screen component
const ManageProductScreen = ({ history }) => {
  // Product data
  const { id: productId } = useParams()
  const products = useProducts(productId)
  const skus = useProductSkus(productId)
  const {
    count,
    goToPage,
    nextPage,
    previousPage
  } = skus

  // State
  const [overlay, setOverlay] = React.useState(false)
  const [inlineError, setInlineError] = React.useState(false)
  const [query, setQuery] = React.useState('')
  const {searchedData} = searchQuery(skus.data.results, query)
  const [sku, setSku] = React.useState(defaultSku)

  // translations
  const { getTranslation } = useTranslations()
  const [productData, setProductData] = React.useState({})
  const [error, setError] = React.useState(false)
  const [internalLoading, setIsLoading] = React.useState(true)
  const [isNew, setIsNew] = React.useState(false)

  // On mount, fetch the user data
  React.useEffect(() => {
    if (!products || !products.data || !products.data.id) {
      setError(true)
    } else {
      const product = products.data 

      setProductData(product)
      skus.fetchRequest()
      setIsLoading(false)
      setError(false)
    }
  }, [productId, products.data])

  React.useEffect(() => {
    if (productData && productData.name) {

      setSku({
        ...sku,
        ref_id: productData.name.replace(/ /g, '').toLowerCase()
      })
    }

  }, [productData])

  defaultSku.product = productId

  // Handle the user changing the data
  const updateUser = (key, value) => {
    const newState = {
      ...productData,
      [key]: value
    }

    setProductData(newState)
  }

  // Handle back
  const handleBack = () => {
    history.push('/products')
  }

  const updateSKU = (key, value) => {
    if (key === 'name') {
      const newRef = `${productData.name.replace(/ /g, '').toLowerCase()}.${value.replace(/ /g, '').toLowerCase()}`
      const newState = {
        ...sku,
        license: {
          ...sku.license,
          ref_id: newRef
        },
        'ref_id': newRef,
        [key]: value,
      }
      setSku(newState)

      return
    }

    if (key !== 'description') {
      const newState = {
        ...sku,
        license: {
          ...sku.license,
          [key]: value,
        }
      }
      setSku(newState)

      return
    }

    const newState = {
      ...sku,
      [key]: value
    }
    setSku(newState)
  }

  React.useEffect(() => {
    if (!overlay) {
      setSku(defaultSku)
      setInlineError(false)
    }
  }, [overlay])

  // Handle add product
  const handleAddSKU = () => {
    if (sku?.name && sku.name.length > 0 && isNew) {
      skus.postRequest({
        ...sku,
        uuid: Date.now()
      }).then((payload) => {
        if (!payload) {
          setInlineError(true)
        } else {
          setInlineError(false)
          setSku(defaultSku)
          setOverlay(false)
        }
      })
    } else if (sku?.name && sku.name.length > 0 && !isNew) {
      skus.putRequest(sku.id, {
        ...sku,
        uuid: Date.now()
      }).then((payload) => {
        if (!payload) {
          setInlineError(true)
        } else {
          setInlineError(false)
          setSku(defaultSku)
          setOverlay(false)
        }
      })
    } else {
      setInlineError(true)
    }
  }

  // Render the component
  return <>
    <Header />
    <MaxWidth>
      <BackButton onClick={handleBack}>
        <img src={backbutton} alt='back to products' />
        {getTranslation('product.manage.back')}
      </BackButton>
      <PageTitle
        title={getTranslation('product.manage.title', [productData.name])}
        buttonText={getTranslation('product.manage.button')}
        handleClick={() => {
          products.putRequest(productData.id, productData).then(() => {
            history.push('/products')
          })
        }}
      />

      <LoadingContainer
        isLoading={(products.isLoading && internalLoading)}
        isError={products.isError || error}
      >
        <>
          <ProductDetails
            data={productData}
            secondary
            handleChange={(key, value) => updateUser(key, value)}
          />

          <LoadingContainer isLoading={skus.isLoading} isError={skus.isError}>
            <SkuButtonContainer>
              <Button secondary onClick={() => {
                setIsNew(true)
                setSku(defaultSku)
                setOverlay(true)
              }}>
                {getTranslation('sku.add')}
              </Button>
            </SkuButtonContainer>

            <SearchModule
              value={query}
              onChange={(v) => setQuery(v)}
              placeholder={getTranslation('sku.search')}
            />

            <RenderList
              data={searchedData}
              fields={['ref_id', 'license.duration', 'license.lease_duration']}
              widths={[ 70, 12, 12 ]}
              handleClick={(item) => {}}
              hasDelete
              hasEdit
              handleOptionsClick={(action, item) => {
                if (action === 'delete') {
                  skus.deleteRequest(item.id, item)
                }
                if (action === 'edit') {
                  setSku(item)
                  setOverlay(true)
                }
              }}
              pagination={{
                'count': count,
                'amountOfItems': (skus?.data?.results?.length || 0),
                'translations': {
                  next: 'Next page',
                  previous: 'Previous page',
                  optionPrefix: 'Page '
                },
                'currentPage': 1,
                'handleNext': () => {
                  nextPage()
                },
                'handlePrevious': () => {
                  previousPage()
                },
                'handleGoToPage': (page) => {
                  goToPage(page)
                },
                'doNotRequestNewPageOnUpdate': true
              }}
            />
          </LoadingContainer>

        </>
      </LoadingContainer>

      <OverlayPanel
        contentWidth={650}
        isOpen={overlay}
        handleClick={() => setOverlay(false)}
      >
        <SKUOverlay
          product={productData}
          data={sku}
          handleUpdate={updateSKU}
          handleAdd={handleAddSKU}
          inlineError={inlineError}
          isNew={isNew}
        />
      </OverlayPanel>

      <Footer />
    </MaxWidth>
  </>
}

export default withRouter(ManageProductScreen)
