import React from 'react'
import { withRouter } from 'react-router-dom'

// View
import RecoverComponent from '../components/recover'

const RecoverScreen = (props) => {
  return <RecoverComponent {...props} />
}

export default withRouter(RecoverScreen)