import React from 'react'
import { withRouter } from 'react-router-dom'

// View
import RecoverValidateComponent from '../components/recoverValidate'

const RecoverValidateScreen = (props) => {
  return <RecoverValidateComponent {...props} />
}

export default withRouter(RecoverValidateScreen)