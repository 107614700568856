import React from 'react';
import { withRouter } from 'react-router-dom';

// Order specific
import OrderDetails from '../components/orders/orderDetails';
import useOrders from '../apis/orders';

// Generic components
import Header from '../components/header';
import MaxWidth from '../core/modules/max-width-content';
import RenderList from '../core/modules/render-list';
import PageTitle from '../core/modules/page-title';
import PageTitleSlots from '../core/modules/page-title-slots';
import OverlayPanel from '../core/modules/overlay-panel';
import SearchModule from '../core/modules/search';
// import searchQuery from '../core/utils/searchQuery'
import { searchAPI } from '../core/utils/searchQuery';
import { useTranslations } from '../translations/useTranslations';
import Footer from '../components/footer';
import LoadingContainer from '../core/modules/loading-container';
import { Button, A } from '../core/ui/fields';

// Screen component
const OrderScreen = ({ history }) => {
  // Order data
  const { data, isLoading, isError, postRequest, deleteRequest, count, goToPage, nextPage, previousPage } = useOrders();
  const [inlineError, setInlineError] = React.useState(false);

  // translations
  const { getTranslation } = useTranslations();

  // State
  const [globalQuery, setGlobalQuery] = React.useState('');
  // const [nameQuery, setNameQuery] = React.useState('');
  const [overlay, setOverlay] = React.useState(false);
  const [results, setResults] = React.useState(data);

  // const { searchedData } = searchAPI(query, apiOptions)

  React.useEffect(() => {
    const _fetch = async () => {
      const options = {
        route: 'v1/orders/search/',
        columns: ['ref_id', 'company', 'first_name', 'last_name'],
      };

      const { results } = data;
      const res = await searchAPI(globalQuery, options, results);
      return res;
    };

    _fetch()
      .then(res => {
        setResults(res);
      })
      .catch(e => {
        console.warn('Erorr detected');
        console.log(e);
      });
      
  }, [globalQuery, data]);



  // React.useEffect(() => {
  //   const _fetch = async () => {
  //     const options = {
  //       route: 'v1/orders/search/',
  //       columns: ['first_name', 'last_name'],
  //     }
  //     const { results } = data;
  //     const res = await searchAPI(nameQuery, options, results);
  //     return res;
  //   };

  //   _fetch()
  //     .then(res => {
  //       setResults(res);
  //     })
  //     .catch(e => {
  //       console.warn('Erorr detected');
  //       console.log(e);
  //     });
  // }, [nameQuery, data]);

  // New user State
  const [user, setUser] = React.useState({
    order_identifier: '',
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    zip: '',
    state: '',
    country: '',
    licenses: [],
  });

  // const exportExpiredOrder
  const handleAddOrder = async () => {
    postRequest(user)
      .then(newOrder => {
        if (newOrder && newOrder.id) {
          history.push(`/order/${newOrder.id}`);
        }
      })
      .catch(() => {
        setInlineError(true);
      });
  };

  const updateUser = (key, value) => {
    const newState = {
      ...user,
      [key]: value,
    };

    setUser(newState);
  };

  const searchStyleOptions = {
    // float: 'left',
    width: '48%',
    margin: '12px 10px',
  };

  // Render
  return (
    <>
      <Header />
      <MaxWidth>

        <PageTitleSlots title={getTranslation('orders.title')} className='buttonHolder'>
          <Button style={{ width: 'auto' }} primary onClick={() => setOverlay(true)}>
            {getTranslation('orders.button')}
          </Button>
        </PageTitleSlots>

        {/* <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}> */}
        {/* style={searchStyleOptions} */}
        <SearchModule value={globalQuery} onChange={v => setGlobalQuery(v)} placeholder={getTranslation('orders.searchPlaceholder')} />
        {/* <SearchModule style={searchStyleOptions} value={nameQuery} onChange={v => setNameQuery(v)} placeholder={getTranslation('orders.searchByName')} /> */}
        {/* </div> */}

        <LoadingContainer isLoading={isLoading} isError={isError}>
          <RenderList
            data={results}
            fields={['ref_id', 'company', 'first_name', 'last_name', 'licenses']}
            widths={[12, 36, 20, 20, 12]}
            handleClick={item => history.push(`/order/${item.id}`)}
            centerCols={['licenses']}
            hasDelete
            handleOptionsClick={(action, item) => {
              deleteRequest(item.id, item);
            }}
            pagination={{
              count: count,
              amountOfItems: data?.results?.length || 0,
              translations: {
                next: 'Next page',
                previous: 'Previous page',
                optionPrefix: 'Page ',
              },
              currentPage: 1,
              handleNext: () => {
                nextPage();
              },
              handlePrevious: () => {
                previousPage();
              },
              handleGoToPage: page => {
                goToPage(page);
              },
              doNotRequestNewPageOnUpdate: true,
            }}
          />
        </LoadingContainer>

        <OverlayPanel contentWidth={720} isOpen={overlay} handleClick={() => setOverlay(false)}>
          <OrderDetails data={user} handleChange={(key, value) => updateUser(key, value)} />
          <Button onClick={() => handleAddOrder()}>{getTranslation('orders.submit')}</Button>
          {inlineError && <p className='error'>{getTranslation('orders.inlineError')}</p>}
        </OverlayPanel>

        <Footer />
      </MaxWidth>
    </>
  );
};

export default withRouter(OrderScreen);
