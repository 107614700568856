import React from 'react'
import styled from 'styled-components'

// Styled footer
const FooterWrapper = styled.div`
  padding: 22px 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: 22px;
  text-align: center;
  color: #8c8c8c;
`

// Component
const Footer = () => {
  return <FooterWrapper>Copyright Smart Delta 2024</FooterWrapper>
}
export default Footer