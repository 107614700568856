import React from 'react'

// My context
const TranslationsContext = React.createContext()

// Context reducer
function translationsReducer(state, action) {
  switch (action.type) {
    case 'merge': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'reset': {
      return {}
    }
    case 'set': {
      return action.payload
    }
    default: {
      return state
    }
  }
}

// Use context hook
export const useTranslations = () => {
  const context = React.useContext(TranslationsContext)
  if (context === undefined) {
    throw new Error('useTranslations must be used with-in the provider')
  }
  return context
}

// Provider
const TranslationsProvider = ({
  children,
  defaultTranslations,
  defaultLang = 'nl',
}) => {
  const [lang, setLang] = React.useState(defaultLang)
  const [state, dispatch] = React.useReducer(
    translationsReducer,
    defaultTranslations
  )

  // Register the translations
  const getTranslation = (key, values) => {
    if (!key || key.length <= 1) {
      throw new Error(
        'You have not assigned a key, or passed valid values to translations.'
      )
    }

    // Construct the path as an array
    const path = key.split('.')

    // Clone the state object, so we don't write too it.
    let translationObject = {
      ...state[lang],
    }

    // Use the path to go through the object.
    if (path && path.length > 0) {
      path.forEach((translationKey) => {
        translationObject =
          translationObject && translationObject[translationKey]
      })
    }

    // If no translation found, return the key string, to show it's broken.
    if (!translationObject) {
      return key
    }

    // Static translations
    if (typeof translationObject === 'string') {
      return translationObject
    }

    // Return the translation, execute it so we can pass dynamic values.
    return translationObject(values)
  }

  // Allow the application to register new translations
  const updateTranslations = (payload) => {
    dispatch({ type: 'merge', payload })
  }

  // Allow the application to set new translations
  const setTranslations = (payload) => {
    dispatch({ type: 'set', payload })
  }

  return (
    <TranslationsContext.Provider
      value={{
        updateTranslations,
        setTranslations,
        getTranslation,
        setLang,
        lang,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export default TranslationsProvider
