import React from 'react'
import styled from 'styled-components'

// Core components
import { H3 } from '../../core/ui/typo'
import { Field, TextField } from '../../core/ui/fields'
import { useTranslations } from '../../translations/useTranslations'

// Styled components
const DetailsContainer = styled.div`
  > div {
    padding: 12px 0;
  }

  .inputDetailWrapper {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;
    margin: 6px 0;
  }

  .extraData {
    opacity: 70%;
  }

  .extraData span {
    display: inline-block;
    padding: 6px 0;
  }

  ${
    props => props.secondary
      ? `
        background: rgba(0,0,0,0.06);
        padding: 22px;
        color: #353535;

        input, textarea {
          background: #fff;
          color: #353535;

          &::placeholder {
            color: rgba(0,0,0,.5);
          }
        }
      `
      : ''
  }
`

// Product details component
const ProductDetails = ({
  data = {},
  secondary,
  handleChange
}) => {
  const { getTranslation } = useTranslations()

  return <DetailsContainer secondary={secondary}>
    <div className='productDetails'>
      <H3> {getTranslation('product.name')} </H3>
      <div className='inputDetailWrapper'>
        <Field
          value={data.name || ''}
          onChange={e => handleChange('name', e.target.value)}
          placeholder={getTranslation('product.name')} />
      </div>
      <H3> {getTranslation('product.description')} </H3>
      <div className='inputDetailWrapper'>
        <TextField
          value={data.description || ''}
          onChange={e => handleChange('description', e.target.value)}
          placeholder={getTranslation('product.description')} />
      </div>
      <div className='extraData'>
        <H3> {getTranslation('product.public_cert')} </H3>
        <span> {data.public_cert || getTranslation('product.no_cert')} </span>
      </div>
    </div>
  </DetailsContainer>
}
export default ProductDetails
